import React, { Fragment, PureComponent } from "react";
import { server } from "../env";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

// Widget Face Components
// Widget Face Components
import Ticker from "./faces/ticker/";
import Text from "./faces/text/";
import Clock from "./faces/clock/";
import GoogleSlides from "./faces/google-slides/";
// import GoogleMaps from "./faces/google-maps/Index";
import Rss from "./faces/rss/";
import Weather from "./faces/weather/index";
import Cricket from "./faces/cricket";
// import PDF from "./faces/pdf";
import Crypto from "./faces/crypto";
import QR from "./faces/qr";
import Twitter from "./faces/twitter";
import Aqi from "./faces/aqi/Index";
import Abp from "./faces/rss-news/Abp";
import Ndtv from "./faces/rss-news/Ndtv";
import Zee from "./faces/rss-news/Zee";
import IndiaToday from "./faces/rss-news/IndiaToday";
import PageLoader from "./components/PageLoader";
import "react-toastify/dist/ReactToastify.css";
import Football from "./faces/football";

export default class Home extends PureComponent {
  state = {
    widgetId:
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ],
    isLoaded: false,
    widget: null,
    loader: "",
    interval: 0,
  };

  componentDidMount = () => {
    const widgetPreview = window.location.href.includes("widgetpreview");

    if (!widgetPreview) {
      this.readWidget();
    } else {
      this.readWidgetPreview();
    }
  };

  async readWidgetPreview() {
    try {
      const { widgetId } = this.state;
      this.setState({ loader: <PageLoader /> });
      const res = await axios.get(
        `${server}/v3/cms/widget/readPublicWidgetPreview/${widgetId}`
      );
      const widget = res.data;
      this.setState({
        widget,
        isLoaded: true,
      });
    } catch (error) {
      console.log(error);
      toast(error.response.data.message || "Something went wrong", {
        theme: "colored",
        type: "error",
      });
    } finally {
      this.setState({ loader: "" });
    }
  }

  readWidget = () => {
    const { widgetId } = this.state;
    try {
      let widget = JSON.parse(localStorage.getItem(widgetId));

      if (JSON.stringify(widget) !== JSON.stringify(this.state.widget)) {
        this.setState({
          widget,
          isLoaded: true,
        });
      }
    } catch (err) {}

    axios
      .get(`${server}/v3/cms/widget/readPublic/${widgetId}`)
      .then((rsp) => {
        if (this.interval) {
          clearTimeout(this.interval);
        }

        let widget = rsp.data;

        if (localStorage.getItem(widgetId) !== JSON.stringify(widget)) {
          localStorage.setItem(widgetId, JSON.stringify(widget));
          this.setState({
            widget,
            isLoaded: true,
          });
        }

        // read widget in every 10 second
        if (
          ["rss", "abp", "zee", "ndtv", "indiaToday", "weather"].includes(
            widget.type
          )
        ) {
          this.interval = setTimeout(() => {
            this.readWidget();
          }, 1000 * 60 * 30);
        } else if (["googleSlides", "pdf", "crypto"].includes(widget.type)) {
          this.interval = setTimeout(() => {
            this.readWidget();
          }, 1000 * 60 * 5);
        } else {
          this.interval = setTimeout(() => {
            this.readWidget();
          }, 10000);
        }
      })
      .catch((err) => {});
  };

  render() {
    const { isLoaded, widget, loader } = this.state;
    const height = window.innerHeight;

    if (loader && window.location.href.includes("widgetpreview")) return loader;
    return (
      <Fragment>
        <div style={{ height: height + "px", overflow: "hidden" }}>
          {isLoaded && (
            <Fragment>
              {widget && (
                <Fragment>
                  {widget.type === "ticker" && <Ticker widget={widget} />}
                  {widget.type === "text" && <Text widget={widget} />}
                  {widget.type === "clock" && <Clock widget={widget} />}
                  {widget.type === "googleSlides" && (
                    <GoogleSlides widget={widget} />
                  )}
                  {widget.type === "rss" && <Rss widget={widget} />}
                  {widget.type === "weather" && <Weather widget={widget} />}
                  {widget.type === "cricket" && <Cricket widget={widget} />}
                  {/* {widget.type === "pdf" && <PDF widget={widget} />} */}
                  {widget.type === "crypto" && <Crypto widget={widget} />}
                  {widget.type === "qr" && <QR widget={widget} />}
                  {widget.type === "twitter" && <Twitter widget={widget} />}
                  {widget.type === "aqi" && <Aqi widget={widget} />}
                  {/* {widget.type === "googleMaps" && (
                    <GoogleMaps widget={widget} />
                  )} */}
                  {widget.type === "abp" && <Abp widget={widget} />}
                  {widget.type === "ndtv" && <Ndtv widget={widget} />}
                  {widget.type === "zee" && <Zee widget={widget} />}
                  {widget.type === "indiaToday" && (
                    <IndiaToday widget={widget} />
                  )}
                  {widget.type === "football" && <Football widget={widget} />}
                </Fragment>
              )}
            </Fragment>
          )}
          {/* {JSON.stringify(widget)} */}
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
}
